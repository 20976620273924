// MultiStepForm.js
import React, { useState } from "react";
import UserInfoForm from "./UserInfoForm";
import PaymentCardForm from "./PaymentCardForm";
import "./MultiStepForm.css";

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    phoneNumber: "",
    cardNumber: "",
    expirationDate: "",
    cvv: "",
    cardholderName: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitUserInfo = (e) => {
    e.preventDefault();
    // Logic to handle form submission for user info
    setStep(2); // Move to the next step
  };

  const handleSubmitPayment = (e) => {
    e.preventDefault();
    // Logic to handle form submission for payment
  };

  return (
    <div className="">
      {step === 1 && (
        <UserInfoForm
          className="multi-step-form"
          formData={formData}
          handleChange={handleChange}
          handleSubmitUserInfo={handleSubmitUserInfo}
        />
      )}
      {step === 2 && (
        <PaymentCardForm
          formData={formData}
          handleChange={handleChange}
          handleSubmitPayment={handleSubmitPayment}
        />
      )}
    </div>
  );
};

export default MultiStepForm;
