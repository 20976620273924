import React from "react";
import "./Offers.css";
import exclusive_image from "../Assets/exclusive_image.png";
import { MaxCard } from "../UI/MaxCard/MaxCard";

const Offers = () => {
  return (
      <MaxCard>
    <div className="offers">
        <div className="offers-left">
          <h1>Exclusive</h1>
          <h1>Offers For You</h1>
          <p>ONLY ON BEST SELLERS PRODUCTS</p>
          <button>Check now</button>
        </div>
        <div className="offers-right">
          <img src={exclusive_image} alt="" />
        </div>
    </div>
      </MaxCard>
  );
};

export default Offers;
