import React, { useState } from "react";
import "./NewsLetter.css";

const NewsLetter = () => {
  const [subscribed, setSubscribed] = useState("Subscribe");

  const handleSubscribe = () => {
    setSubscribed("Subscribed");
  };

  return (
    <div className="newsletter">
      <h1>Get Exclusive Offers On Your Email</h1>
      <div className="input-div">
        <button onClick={handleSubscribe}>{subscribed}</button>
      </div>
    </div>
  );
};

export default NewsLetter;
