import React, { useEffect, useState } from "react";
import Hero from "../Components/Hero/Hero";
import Popular from "../Components/Popular/Popular";
import Offers from "../Components/Offers/Offers";
import NewCollections from "../Components/NewCollections/NewCollections";
import NewsLetter from "../Components/NewsLetter/NewsLetter";
import "./CSS/Product.css";

const Shop = () => {
  const [popular, setPopular] = useState([]);
  const [newcollection, setNewCollection] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchInfo = () => {
    fetch("https://main.healthyharvest.fit/api/popularinwomen")
      .then((res) => res.json())
      .then((data) => setPopular(data));
    fetch("https://main.healthyharvest.fit/api/newcollections")
      .then((res) => res.json())
      .then((data) => setNewCollection(data))
      .finally(() => setLoading(false)); // Set loading to false when fetching is done
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <div>
      {loading && (
        <div className="loading-modal">
          <div className="loader">
            <svg
              className="spinner"
              width="60px"
              height="60px"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="path"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      )}
      {!loading && (
        <>
          <Hero />
          <Popular data={popular} />
          <Offers />
          <NewCollections data={newcollection} />
          <NewsLetter />
        </>
      )}
    </div>
  );
};

export default Shop;
