import arrow_icon from "../Assets/arrow.png";
import { MaxCard } from "../UI/MaxCard/MaxCard";
import "./Banner.css";

const Banner = (props) => {
  return (
    <div className={`Banner `}>
      <MaxCard className="maxcard-flex">
        <div className="Banner-left">
          <h2>WELCOME TO {props.category_name}</h2>
          <div>
            <p>Get your</p>
            <p>{props.category_name}</p>
            <p>here!!</p>
          </div>
          <div className="Banner-latest-btn">
            <div>Check Out</div>
            <img src={arrow_icon} alt="" />
          </div>
        </div>
        <div className="Banner-right">
          <img src={props.image} alt="Banner" />
        </div>
      </MaxCard>
    </div>
  );
};

export default Banner;
