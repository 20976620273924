import React from 'react'
import MultiStepForm from '../Components/PaymentCardForm/MultiStep'

const Payment = () => {
  return (
    <div>
      <MultiStepForm/>
    </div>
  )
}

export default Payment
