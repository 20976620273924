import React, { useContext, useState } from "react";
import "./PaymentCardForm.css"; // Import CSS file for styling
import { MaxCard } from "../UI/MaxCard/MaxCard";
import { ShopContext } from "../../Context/ShopContext";

const PaymentCardForm = ({ className }) => {
  const [transferConfirmed, setTransferConfirmed] = useState(false);
  const [codSubmitted, setCodSubmitted] = useState(false);
  const { getTotalCartAmount, removeFromCart, products } =
    useContext(ShopContext);

  const authToken = localStorage.getItem("auth-token");
  const user = []; // assuming this is how you retrieve the user array

  const formatPrice = (price) => {
    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "NGN",
    });
  };
  // Handle "I have transferred" button click for transfer option
  const handleTransferredButtonClick = () => {
    if (!authToken || user.length === 0) {
      alert("Please login to perform this action.");
    } else {
      // Notify to hold for confirmation
      alert("Your transfer is being processed. Please wait for confirmation.");

      // Set transfer confirmation state
      setTransferConfirmed(true);

      // Remove all items from the cart
      removeFromCart(products.id);
    }
  };

  // Handle cash on delivery submission
  const handleCodSubmit = () => {
    if (!authToken || user.length === 0) {
      alert("Please login to perform this action.");
    } else {
      // Check if input value is provided
      const inputValue = document.getElementById("email").value;
      if (!inputValue) {
        return; // If no value, do nothing
      }

      // Notify the user that the order is being processed
      alert(
        "Your order is being processed. You will receive a notification shortly with the amount to pay."
      );

      // Set cash on delivery submission state
      setCodSubmitted(true);

      // Remove all items from the cart
      removeFromCart();

      // Redirect to home page after showing the alert and notification
      window.location.href = "/";
    }
  };

  return (
    <MaxCard className="payment-container">
      <div>
        <div>
          <h1>You will be transferring {formatPrice(getTotalCartAmount())}</h1>
          <div className="transfer-details">
            <h2>Transfer Details</h2>
            <div className="account-details">
              <p>
                Business Account Number: <strong>0875406670</strong>
              </p>
              <p>
                Bank Name: <strong>Guaranty Trust Bank (GTB)</strong>
              </p>
              <p>
                Account Name: <strong>Charisog Posh Ventures</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="confirmation-card">
          <h2>Transfer Confirmation</h2>
          {transferConfirmed ? (
            <div className="confirmation-details">
              <p>Your transfer will be confirmed.</p>
              <p>You will be contacted within 24 hours.</p>
              <p>
                Business Account Number: <strong>0875406670</strong>
              </p>
              <p>
                Bank Name: <strong>Guaranty Trust Bank (GTB)</strong>
              </p>
              <p>
                Account Name: <strong>Charisog Posh Ventures</strong>
              </p>
              <p>
                For Issues Call: <strong>08066205194</strong>
              </p>
            </div>
          ) : (
            <button onClick={handleTransferredButtonClick}>
              I have transferred
            </button>
          )}
        </div>
      </div>

      <hr />
      <div>
        <h1>Cash on Delivery</h1>
        <p>
          Note: <strong>Pay on delivery if you want to Pickup Prepaid.</strong>
        </p>
        <form action="https://formspree.io/f/xpzvdkej" method="POST">
          <label htmlFor="name">Your Name</label>
          <input type="text" id="name" name="name" />
          <label htmlFor="text">Submit your Number | Email</label>
          <input type="" id="text" name="text" />
          <input type="hidden" name="payment_method" value="Cash on Delivery" />
          <input type="hidden" name="_subject" value="New COD Order" />
          <button type="submit" onClick={handleCodSubmit}>
            Click Here To Approve
          </button>
        </form>
      </div>
    </MaxCard>
  );
};

export default PaymentCardForm;
