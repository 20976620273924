// UserInfoForm.js
import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import "./UserInfoForm.css";

const UserInfoForm = ({ handleSubmitUserInfo }) => {
  const [state, handleSubmit] = useForm("myyrwlkp");

  return (
    <div className="form-container">
      <form
        onSubmit={(e) => {
          handleSubmit(e);
          handleSubmitUserInfo(e);
        }}
        className="form-group"
      >
        <label htmlFor="name">Name</label>
        <input id="name" type="text" name="name" required />
        <ValidationError prefix="Name" field="name" errors={state.errors} />

        <label htmlFor="email">Email Address</label>
        <input id="email" type="email" name="email" required />
        <ValidationError prefix="Email" field="email" errors={state.errors} />

        <label htmlFor="tel">Phone Number</label>
        <input id="tel" type="tel" name="tel" required />
        <ValidationError prefix="tel" field="tel" errors={state.errors} />

        <label htmlFor="address">Address (Location)</label>
        <textarea id="address" name="address" required />
        <ValidationError
          prefix="Address"
          field="address"
          errors={state.errors}
        />

        <button type="submit" disabled={state.submitting}>
          Next
        </button>
      </form>
    </div>
  );
};

export default UserInfoForm;
