import React from "react";
import CartItems from "../Components/CartItems/CartItems";
import { MaxCard } from "../Components/UI/MaxCard/MaxCard";

const Cart = () => {
  return (
    <MaxCard>
      <CartItems />
    </MaxCard>
  );
};

export default Cart;
