import React from "react";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Shop from "./Pages/Shop";
import Cart from "./Pages/Cart";
import Product from "./Pages/Product";
import Footer from "./Components/Footer/Footer";
import ShopCategory from "./Pages/ShopCategory";
import LoginSignup from "./Pages/LoginSignup";
import Payment from "./Pages/Payment";
import Banner from "./Components/Banner/Banner";
import grocery_banner from "../src/Components/Assets/grocerybanner.png";
import health_banner from "../src/Components/Assets/makeup.png";
import sport_banner from "../src/Components/Assets/sport_fit.png";
import home_kitchen from "../src/Components/Assets/bedsheet.png";
import { RefreshProvider } from "./Context/RefreshProvider";

function App() {
  // Prevent reload or refresh prompt

  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Shop gender="all" />} />
          <Route
            path="/grocery"
            element={
              <ShopCategory
                banner={
                  <Banner category_name="Grocery" image={grocery_banner} />
                }
                category="grocery"
              />
            }
          />
          <Route
            path="/health-and-beauty"
            element={
              <ShopCategory
                banner={
                  <Banner
                    category_name="Health And Beauty"
                    image={health_banner}
                  />
                }
                category="health-and-beauty"
              />
            }
          />
          <Route
            path="/sports-and-fitness"
            element={
              <ShopCategory
                banner={
                  <Banner
                    category_name="Sports And Fitness"
                    image={sport_banner}
                  />
                }
                category="sports-and-fitness"
              />
            }
          />
          <Route
            path="/home-and-kitchen"
            element={
              <ShopCategory
                banner={
                  <Banner
                    category_name="Home and Kitchen"
                    image={home_kitchen}
                  />
                }
                category="home-and-kitchen"
              />
            }
          />
          <Route path="/product" element={<Product />} />
          <Route path="/product/:productId" element={<Product />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<LoginSignup />} />
          <Route path="/payment" element={<Payment />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
