import "./Hero.css";
import arrow_icon from "../Assets/arrow.png";
import { MaxCard } from "../UI/MaxCard/MaxCard";
import useheroimage from "../Assets/useheroimage.png";

const Hero = () => {
  return (
    <div className="hero">
      <MaxCard className="maxcard-flex">
        <div className="hero-left">
          <h2>WELCOME TO HEALTHY HARVEST</h2>
          <div>
            <p>Harvest</p>
            <p>for your</p>
            <p>taste!!</p>
          </div>
          <div className="hero-latest-btn">
            <div>Latest Collection</div>
            <img src={arrow_icon} alt="" />
          </div>
        </div>
        <div className="hero-right">
          <img src={useheroimage} alt="hero" />
        </div>
      </MaxCard>
    </div>
  );
};

export default Hero;
