import React, { useEffect, useState } from "react";
import "./CSS/ShopCategory.css";
import Item from "../Components/Item/Item";
import { MaxCard } from "../Components/UI/MaxCard/MaxCard";

const ShopCategory = (props) => {
  const [allproducts, setAllProducts] = useState([]);

  const fetchInfo = () => {
    fetch("https://main.healthyharvest.fit/api/allproducts")
      .then((res) => res.json())
      .then((data) => setAllProducts(data));
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <MaxCard>
      <div className="shopcategory">
        <div className="shopcategory-banner">{props.banner}</div>
        <div className="shopcategory-products">
          {allproducts.map((item, i) => {
            if (props.category === item.category) {
              return (
                <Item
                  id={item.id}
                  key={i}
                  name={item.name}
                  image={item.image}
                  new_price={item.new_price}
                  old_price={item.old_price}
                  description={item.description}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </MaxCard>
  );
};

export default ShopCategory;
