import React, { useContext, useRef, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import logo from "../Assets/logo.png";
import cart_icon from "../Assets/cart_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import nav_dropdown from "../Assets/nav_dropdown.png";
import { MaxCard } from "../UI/MaxCard/MaxCard";

const Navbar = () => {
  let [menu, setMenu] = useState("grocery");
  const { getTotalCartItems } = useContext(ShopContext);

  const menuRef = useRef();

  const dropdown_toggle = (e) => {
    menuRef.current.classList.toggle("nav-menu-visible");
    e.target.classList.toggle("open");
  };

  const closeDropdown = () => {
    menuRef.current.classList.remove("nav-menu-visible");
    document.querySelector(".nav-dropdown").classList.remove("open");
  };

  return (
    <MaxCard>
      <div className="nav">
        <Link
          to="/"
          onClick={() => {
            setMenu("");
            closeDropdown(); // Close dropdown when logo is clicked
          }}
          style={{ textDecoration: "none" }}
          className="nav-logo"
        >
          <img src={logo} alt="logo" />
          <p>HEALTHY HARVEST</p>
        </Link>
        <img
          onClick={dropdown_toggle}
          className="nav-dropdown"
          src={nav_dropdown}
          alt=""
        />
        <ul ref={menuRef} className="nav-menu">
          <li
            onClick={() => {
              setMenu("grocery");
              closeDropdown(); // Close dropdown when grocery is clicked
            }}
          >
            <Link to="/grocery" style={{ textDecoration: "none" }}>
              Grocery
            </Link>
            {menu === "grocery" ? <hr /> : <></>}
          </li>
          <li
            onClick={() => {
              setMenu("health-and-beauty");
              closeDropdown(); // Close dropdown when health-and-beauty is clicked
            }}
          >
            <Link to="/health-and-beauty" style={{ textDecoration: "none" }}>
              Health And Beauty
            </Link>
            {menu === "health-and-beauty" ? <hr /> : <></>}
          </li>
          <li
            onClick={() => {
              setMenu("sports-and-fitness");
              closeDropdown(); // Close dropdown when sports-and-fitness is clicked
            }}
          >
            <Link to="/sports-and-fitness" style={{ textDecoration: "none" }}>
              Sports And Fitness
            </Link>
            {menu === "sports-and-fitness" ? <hr /> : <></>}
          </li>
          <li
            onClick={() => {
              setMenu("home-and-kitchen");
              closeDropdown(); // Close dropdown when home-and-kitchen is clicked
            }}
          >
            <Link to="/home-and-kitchen" style={{ textDecoration: "none" }}>
              Home And Kitchen
            </Link>
            {menu === "home-and-kitchen" ? <hr /> : <></>}
          </li>
        </ul>
        <div className="nav-login-cart">
          {localStorage.getItem("auth-token") ? (
            <button
              onClick={() => {
                localStorage.removeItem("auth-token");
                window.location.replace("/");
              }}
            >
              Logout
            </button>
          ) : (
            <Link to="/login" style={{ textDecoration: "none" }}>
              <button>Login</button>
            </Link>
          )}
          <Link to="/cart">
            <img src={cart_icon} alt="cart" />
          </Link>
          <div className="nav-cart-count">{getTotalCartItems()}</div>
        </div>
      </div>
    </MaxCard>
  );
};

export default Navbar;
