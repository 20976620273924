import React from "react";
import "./MaxCard.css";

export const MaxCard = ({ children, className }) => {
  return (
    <div className="max-cards">
      <div className={`max-card ${className}`}>{children}</div>
    </div>
  );
};
