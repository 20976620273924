import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./CartItems.css";
import cross_icon from "../Assets/cart_cross_icon.png";
import { ShopContext } from "../../Context/ShopContext";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";

const CartItems = () => {
  const navigate = useNavigate();
  const {
    products,
    cartItems,
    removeFromCart,
    getTotalCartAmount,
    setCartItems, // Assuming setCartItems is available in ShopContext
  } = useContext(ShopContext);
  const [loading, setLoading] = useState(false);
  const [subtotal, setSubtotal] = useState(0);

  useEffect(() => {
    const calculateTotal = async () => {
      setLoading(true);
      setTimeout(async () => {
        const total = await getTotalCartAmount();
        setSubtotal(total);
        setLoading(false);
      }, 3000); // 3 seconds delay
    };

    calculateTotal();
  }, [cartItems, getTotalCartAmount]);

  const formatPrice = (price) => {
    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "NGN",
    });
  };

  const handleInputChange = (productId, quantity) => {
    if (quantity >= 0) {
      if (quantity === 0) {
        removeFromCart(productId);
      } else {
        // Update cartItems for the specific product being interacted with
        setCartItems((prevCartItems) => ({
          ...prevCartItems,
          [productId]: quantity,
        }));
      }
      const newTotal = getTotalCartAmount();
      setSubtotal(newTotal);
    }
  };

  // Filter products to display only those that have been just added to the cart
  const addedProducts = products.filter((product) => cartItems[product.id] > 0);

  const handleProceedToCheckout = () => {
    navigate("/payment");
  };

  return (
    <div className="cartitems">
      <div className="cartitems-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {addedProducts.length === 0 ? (
        <div className="no-cart">
          <ProductionQuantityLimitsIcon
            sx={{ fontSize: "80px", color: "#454545" }}
          />
          <p>No Cart Added</p>
        </div>
      ) : (
        addedProducts.map((product) => {
          return (
            <div key={product.id}>
              <div className="cartitems-format-main cartitems-format">
                <img
                  className="cartitems-product-icon"
                  src={product.image}
                  alt=""
                />
                <p cartitems-product-title>{product.name}</p>
                <p>{formatPrice(product.new_price)}</p>
                <input
                  className="cartitems-quantity"
                  type="number"
                  min="0"
                  value={cartItems[product.id] || 0}
                  onChange={(e) =>
                    handleInputChange(product.id, parseInt(e.target.value))
                  }
                />
                <p>
                  {formatPrice(
                    product.new_price * (cartItems[product.id] || 0)
                  )}
                </p>
                <img
                  onClick={() => {
                    removeFromCart(product.id);
                  }}
                  className="cartitems-remove-icon"
                  src={cross_icon}
                  alt=""
                />
              </div>
              <hr />
            </div>
          );
        })
      )}
      <div className="cartitems-down">
        <div className="cartitems-total">
          <h1>Cart Totals</h1>
          <div>
            <div className="cartitems-total-item">
              <p>Subtotal</p>
              {loading ? <p>loading...</p> : <p>{formatPrice(subtotal)}</p>}
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>Shipping Fee</p>
              <p>-</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <h3>Total</h3>
              {loading ? <p>loading...</p> : <p>{formatPrice(subtotal)}</p>}
            </div>
          </div>
          <button onClick={handleProceedToCheckout}>PROCEED TO CHECKOUT</button>
        </div>
      </div>
    </div>
  );
};

export default CartItems;
