import React, { useState } from "react";
import "./CSS/LoginSignup.css";

const LoginSignup = () => {
  const [state, setState] = useState("Login");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    token: "",
    newPassword: "",
  });
  const [loading, setLoading] = useState(false); // State to track loading

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const login = async () => {
    setLoading(true); // Set loading to true before fetching
    let dataObj;
    await fetch("https://main.healthyharvest.fit/api/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((resp) => resp.json())
      .then((data) => {
        dataObj = data;
      });
    setLoading(false); // Set loading to false after fetching

    if (dataObj.success) {
      localStorage.setItem("auth-token", dataObj.token);
      window.location.replace("/");
    } else {
      alert(dataObj.error);
    }
  };

  const signup = async () => {
    setLoading(true); // Set loading to true before fetching
    let dataObj;
    await fetch("https://main.healthyharvest.fit/api/signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((resp) => resp.json())
      .then((data) => {
        dataObj = data;
      });
    setLoading(false); // Set loading to false after fetching

    if (dataObj.success) {
      localStorage.setItem("auth-token", dataObj.token);
      window.location.replace("/");
    } else {
      alert(dataObj.error);
    }
  };

  const forgotPasswordHandler = async () => {
    setLoading(true); // Set loading to true before fetching
    let dataObj;
    await fetch("https://main.healthyharvest.fit/api/forgot-password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: formData.email }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        dataObj = data;
      });
    setLoading(false); // Set loading to false after fetching

    if (dataObj.success) {
      alert("Password reset instructions sent to your email");
      setForgotPassword(false);
    } else {
      alert(dataObj.error);
    }
  };

  return (
    <div className="loginsignup">
      {loading && (
        <div className="loading-modal">
          <div className="loader">
            <svg
              className="spinner"
              width="60px"
              height="60px"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="path"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      )}
      <div className="loginsignup-container">
        {forgotPassword ? (
          // Forgot Password Form
          <div className="forgot-password-form">
            <h2>Forgot Password</h2>
            <input
              type="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              style={{ marginBottom: "10px" }}
            />
            <button onClick={forgotPasswordHandler}>Submit</button>
            <button onClick={() => setForgotPassword(false)}>Cancel</button>
          </div>
        ) : (
          // Login/Signup Form
          <>
            <h1>{state}</h1>
            <div className="loginsignup-fields">
              {state === "Sign Up" && (
                <input
                  type="text"
                  placeholder="Your name"
                  name="username"
                  value={formData.username}
                  onChange={changeHandler}
                />
              )}
              <input
                type="email"
                placeholder="Email address"
                name="email"
                value={formData.email}
                onChange={changeHandler}
              />
              <input
                type="password"
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={changeHandler}
              />
              {state === "Reset Password" && (
                <>
                  <input
                    type="text"
                    placeholder="Token"
                    name="token"
                    value={formData.token}
                    onChange={changeHandler}
                  />
                  <input
                    type="password"
                    placeholder="New Password"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={changeHandler}
                  />
                </>
              )}
            </div>

            <button
              onClick={() => {
                state === "Login" ? login() : signup();
              }}
            >
              {state === "Reset Password" ? "Submit" : "Continue"}
            </button>

            {state === "Login" && (
              <>
                <p className="loginsignup-login">
                  Create an account?
                  <span onClick={() => setState("Sign Up")}>Click here</span>
                </p>
                <p className="loginsignup-login">
                  Forgot Password?
                  <span onClick={() => setForgotPassword(true)}>
                    Click here
                  </span>
                </p>
              </>
            )}
            {state === "Sign Up" && (
              <p className="loginsignup-login">
                Already have an account?{" "}
                <span onClick={() => setState("Login")}>Login here</span>
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LoginSignup;
