    import React, { createContext, useEffect, useState } from "react";

    export const ShopContext = createContext(null);

    const ShopContextProvider = (props) => {
      const [products, setProducts] = useState([]);

      const getDefaultCart = () => {
        let cart = {};
        for (let i = 0; i < 300; i++) {
          cart[i] = 0;
        }
        return cart;
      };

      const [cartItems, setCartItems] = useState(getDefaultCart());

      useEffect(() => {
        fetch("https://main.healthyharvest.fit/api/allproducts")
          .then((res) => res.json())
          .then((data) => setProducts(data));

        if (localStorage.getItem("auth-token")) {
          fetch("https://main.healthyharvest.fit/api/getcart", {
            method: "POST",
            headers: {
              Accept: "application/form-data",
              "auth-token": `${localStorage.getItem("auth-token")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(),
          })
            .then((resp) => resp.json())
            .then((data) => {
              setCartItems(data);
            });
        }
      }, []);

      const getTotalCartAmount = () => {
        let totalAmount = 0;
        for (const item in cartItems) {
          if (cartItems[item] > 0) {
            const itemInfo = products.find(
              (product) => product.id === Number(item)
            );
            if (itemInfo && itemInfo.new_price) {
              totalAmount += cartItems[item] * itemInfo.new_price;
            } else {
              // Product is unavailable, set its total amount to 0
              totalAmount += 0;
            }
          }
        }
        return totalAmount;
      };

      const getTotalCartItems = () => {
        let totalItem = 0;
        for (const item in cartItems) {
          const product = products.find((p) => p.id === Number(item));
          if (product && cartItems[item] > 0) {
            totalItem += cartItems[item];
          } else {
            // If the product doesn't exist or has been deleted, remove its quantity from the total count
            setCartItems((prevCartItems) => {
              const updatedCartItems = { ...prevCartItems };
              delete updatedCartItems[item];
              return updatedCartItems;
            });
          }
        }
        return totalItem;
      };


      const addToCart = (itemId) => {
        setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
        if (localStorage.getItem("auth-token")) {
          fetch("https://main.healthyharvest.fit/api/addtocart", {
            method: "POST",
            headers: {
              Accept: "application/form-data",
              "auth-token": `${localStorage.getItem("auth-token")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ itemId: itemId }),
          })
            .then((resp) => resp.json())
            .then((data) => {
              console.log(data);
            });
        }
      };

      const removeFromCart = (itemId) => {
        setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
        if (localStorage.getItem("auth-token")) {
          fetch("https://main.healthyharvest.fit/api/removefromcart", {
            method: "POST",
            headers: {
              Accept: "application/form-data",
              "auth-token": `${localStorage.getItem("auth-token")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ itemId: itemId }),
          })
            .then((resp) => resp.json())
            .then((data) => {
              console.log(data);
            });
        }
      };

      const contextValue = {
        products,
        getTotalCartItems,
        cartItems,
        addToCart,
        removeFromCart,
        getTotalCartAmount,
        setCartItems,
      };
      return (
        <ShopContext.Provider value={contextValue}>
          {props.children}
        </ShopContext.Provider>
      );
    };

    export default ShopContextProvider;
